<template>

  <!-- {{user}} -->
  <div class="col-lg-9 col-md-9">
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{$t('label.bindingCreditCard')}}</h4>
      </template>
      <template v-slot:body>
        <form>
          <div class="row align-items-center">
            <div class="form-group col-sm-6">
              <label for="fname" class="form-label">{{$t('label.iDNumber')}}</label>
              <input type="text" v-model="form.user_sn" class="form-control" id="user_sn" name="user_sn" :placeholder="$t('label.pleaseEnterYourIDNumber')"
                :class="{ 'is-invalid':  v$.form.user_sn.$dirty && !v$.form.user_sn.$pending && v$.form.user_sn.$invalid }" />
              <span v-if=" v$.form.user_sn.$dirty && !v$.form.user_sn.$pending && v$.form.user_sn.$invalid" class="invalid-feedback">{{ $t('messages.required') }}</span>
            </div>
            <div class="form-group col-sm-6">
              <label for="fname" class="form-label">{{$t('label.email')}}</label>
              <input type="text" v-model="form.user_email" class="form-control" id="email" name="email" :placeholder="$t('label.emailPlaceholder')"
                :class="{ 'is-invalid':  v$.form.user_email.$dirty && !v$.form.user_email.$pending && v$.form.user_email.$invalid }" />
              <span v-if=" v$.form.user_email.$dirty && !v$.form.user_email.$pending && v$.form.user_email.$invalid" class="invalid-feedback">{{ $t('messages.required') }}</span>
            </div>

            <div class="form-group col-sm-6">
              <label for="uname" class="form-label">{{$t('label.cardName')}}</label>
              <!-- <div class="fab fa-cc-visa ps-2"></div> -->
              <input type="text" v-model="form.user_real_name" class="form-control" id="card_number" name="card_number" :placeholder="$t('label.cardNamePlaceholder')"
                :class="{ 'is-invalid':  v$.form.user_real_name.$dirty && !v$.form.user_real_name.$pending && v$.form.user_real_name.$invalid }" maxlength="19" />
              <span v-if=" v$.form.user_real_name.$dirty && !v$.form.user_real_name.$pending && v$.form.user_real_name.$invalid" class="invalid-feedback">{{ $t('messages.required') }}</span>
            </div>

            <div class="form-group col-sm-6">
              <label for="uname" class="form-label">{{$t('label.cardholderEnglishName')}}</label>
              <!-- <div class="fab fa-cc-visa ps-2"></div> -->
              <input type="text" v-model="form.user_english_name" class="form-control" id="user_english_name" name="user_english_name"
                :placeholder="$t('label.pleaseEnterTheEnglishNameOfTheCardholder')"
                :class="{ 'is-invalid':  v$.form.user_english_name.$dirty && !v$.form.user_english_name.$pending && v$.form.user_english_name.$invalid }" maxlength="19" />
              <span v-if=" v$.form.user_english_name.$dirty && !v$.form.user_english_name.$pending && v$.form.user_english_name.$invalid" class="invalid-feedback">{{ $t('messages.required') }}</span>
            </div>
            <div class="form-group col-sm-6">
              <label for="uname" class="form-label">{{$t('label.telephone')}}</label>
              <input type="text" v-model="form.user_cellphone" class="form-control" id="user_cellphone" name="user_cellphone" :placeholder="$t('label.pleaseEnterPhoneNumber')"
                :class="{ 'is-invalid':  v$.form.user_cellphone.$dirty && !v$.form.user_cellphone.$pending && v$.form.user_cellphone.$invalid }" maxlength="19" pattern="[0-9]*" />
              <span v-if=" v$.form.user_cellphone.$dirty && !v$.form.user_cellphone.$pending && v$.form.user_cellphone.$invalid" class="invalid-feedback">{{ $t('messages.required') }}</span>
            </div>
            <div class="form-group col-sm-6">
              <label for="uname" class="form-label">{{$t('label.address')}}</label>
              <input type="text" v-model="form.user_address" class="form-control" id="user_address" name="user_address" :placeholder="$t('label.pleaseEnterAddress')"
                :class="{ 'is-invalid':  v$.form.user_address.$dirty && !v$.form.user_address.$pending && v$.form.user_address.$invalid }" maxlength="19" />
              <span v-if=" v$.form.user_address.$dirty && !v$.form.user_address.$pending && v$.form.user_address.$invalid" class="invalid-feedback">{{ $t('messages.required') }}</span>
            </div>
            <div class="form-group col-sm-12">
              <label for="type" class="form-label">{{$t('發卡銀行所在地')}}</label>
              <select v-model="form.type" class="form-control" id="type" name="type"
                :class="{ 'is-invalid': v$.form.type.$dirty && !v$.form.type.$pending && v$.form.type.$invalid }">
                <option value="">{{$t('請選擇發卡銀行所在地')}}</option>
                <option value="1">{{$t('海內')}}</option>
                <option value="2">{{$t('海外')}}</option>
              </select>
              <span v-if="v$.form.type.$dirty && !v$.form.type.$pending && v$.form.type.$invalid"
                class="invalid-feedback">{{ $t('messages.required') }}</span>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <button @click="onSubmit()" type="button" class="btn btn-primary me-2">
              {{$t('button.submit')}}
            </button>
          </div>
        </form>
      </template>
    </iq-card>
  </div>
</template>
<script>
import { addCreditCard, getCreditCard } from '@/api/creditCard'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { mapGetters } from 'vuex'
import { commomData } from '@/utils/commonVmodel'
export default {
  setup() {
    const v$ = useVuelidate()
    return { v$ }
  },
  computed: {
    ...mapGetters({
      user: 'User/userState',
      token: 'User/tokenState'
    })
  },
  data() {
    return {
      commomData: commomData(),
      form: {
        // email: 'test@gmail.com',
        // card_name: 'Ryan Wu',
        // card_number: '8302178921213321',
        // card_expiration_year: '25',
        // card_expiration_month: '07',
        // card_cvc: '836'
        user_sn: '',
        user_cellphone: '',
        user_real_name: '',
        user_email: '',
        user_english_name: '',
        user_address: '',
        type: '' // 新增發卡銀行所在地
      }
    }
  },
  validations() {
    return {
      form: {
        user_email: { required, email },
        user_sn: { required },
        user_cellphone: { required },
        user_real_name: { required },
        user_english_name: { required },
        user_address: { required },
        type: { required } // 驗證發卡銀行所在地
      }
    }
  },
  created() {
    this.getRow()
  },
  watch: {
    'form.card_number'() {
      // Card number without dash (-)
      let realNumber = this.form.card_number.replace(/-/gi, '')

      // Generate dashed number
      let dashedNumber = realNumber.match(/.{1,4}/g)

      // Replace the dashed number with the real one
      this.form.card_number = dashedNumber.join('-')
    }
  },
  methods: {
    async onSubmit() {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }
      this.commomData.btnLoader = true
      addCreditCard(this.form)
        .then(res => {
          console.log(res)
          this.$swal.mixin().fire({
            icon: 'success',
            text: res.message
          })
          this.getRow()
          this.commomData.btnLoader = false
        })
        .catch(err => {
          this.commomData.btnLoader = false
          console.log(err)
          this.$swal.mixin().fire({
            icon: 'error',
            text: err.response.data.message
          })
        })
    },
    getRow() {
      getCreditCard()
        .then(res => {
          console.log(res)
          // 是綁定才binding
          if (res.data.is_card) {
            this.form = res.data
            let dashedNumber = this.form.card_number.match(/.{1,4}/g)
            // Replace the dashed number with the real one
            this.form.card_number = dashedNumber.join('-')
          }
        })
        .catch(err => {
          console.log(err)
          this.$swal.mixin().fire({
            icon: 'error',
            text: err.response.data.message
          })
        })
    }
  }
}
</script>
